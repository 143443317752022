export const languages = {
  English: {
    All: {
      Home: "Home",
      About: "About",
      Markets: "Markets",
      Pricing: "Pricing",
      Register: "Register",
      Login: "Log in",
      ReadMore: "Read more",
      JoinNow: "Join now",
      Navigate: "NAVIGATE",
      AboutUs: "About us",
      SignIn: "Sign in",
      Account: "Account",
      SignUp: "Sign up",
      Email: "Email",
      Password: "Password",
      LastName: "Last Name",
      FirstName: "First Name",
      Address: "Address",
      PostalCode: "Postal code",
      Phone: "Phone",
      PromoCode: "Promo code",
      Country: "Country",
      Currency: "Currency",
      AllreadyHave: "Already have an account?",
      DontHaveAcc: "Dont have account?",
      Loading: "Loading",
      OurPricingPlans:"Our Pricing plans"
    },
    SecWhoWeContant: {
      Charting:
        "Charting Your Financial Future: Where Expertise Meets Opportunity",
      AtCoin:
        "At Coineurocapital, our legacy in the stock market spans over two decades, positioning us as trusted partners in your journey towards financial prosperity. Our expert team offers bespoke trading solutions, meticulously crafted to align with your unique investment objectives and risk appetite. From insightful market analysis to hands-on portfolio management, we empower you to make informed decisions and seize lucrative opportunities. Experience the difference with Coineurocapital, where expertise meets excellence, propelling your investments to new heights.",
    },
    SecOurServices: {
      Title: "Our Core Services",
      Text: "Proactive management ensuring balanced portfolios aligned with your investment objectives.",
    },
    SectionHeading: {
      Title: "Multi-Market Trading Platform",
      Text: "Discover a comprehensive trading platform offering access to global forex, stocks, commodities, futures, cryptocurrencies, and CFDs. With expert analysis and insights, seize opportunities across diverse markets, empowering you to navigate fluctuations and achieve your financial goals with confidence.",
    },
    SecWelcomeContent: {
      Discover: "Discover a new ways to enjoy your World!",
      Experience: "Experience Drives Success",
      For20:
        "For over 20 years, we've been committed to excellence in the stock market. Our track record speaks for itself, as we've consistently delivered superior results and earned the trust of our clients. Partner with us for a journey towards financial success. ",
    },
  },
  French: {
    All: {
      Home: "Accueil",
      About: "À propos",
      Markets: "Marchés",
      Pricing: "Tarification",
      Register: "S'inscrire",
      Login: "Se connecter",
      ReadMore: "Lire la suite",
      JoinNow: "Rejoignez maintenant",
      Navigate: "NAVIGUER",
      AboutUs: "À propos de nous",
      SignIn: "Se connecter",
      SignUp: "S'inscrire",
      Account: "Compte",
      Email: "Email",
      Password: "Mot de passe",
      LastName: "Nom de famille",
      FirstName: "Prénom",
      Address: "Adresse",
      PostalCode: "Code Postal",
      Phone: "Numéro de téléphone",
      PromoCode: "Code promo",
      Country: "Pays",
      Currency: "Devise",
      AllreadyHave: "Vous avez déjà un compte ?",
      Loading: "Chargement",
      DontHaveAcc: "Vous n'avez pas de compte ?",
      OurPricingPlans:"Nos Plans Tarifaires"
    },
    SecWhoWeContant: {
      Charting:
        "Planifier votre avenir financier : là où l'expertise rencontre l'opportunité",
      AtCoin:
        "Chez Coineurocapital, notre héritage sur le marché boursier s'étend sur plus de deux décennies, ce qui nous positionne comme des partenaires de confiance dans votre parcours vers la prospérité financière. Notre équipe d'experts propose des solutions de trading sur mesure, minutieusement conçues pour s'aligner sur vos objectifs d'investissement uniques et votre appétit pour le risque. De l'analyse de marché perspicace à la gestion de portefeuille pratique, nous vous permettons de prendre des décisions éclairées et de saisir des opportunités lucratives. Faites l'expérience de la différence avec Coineurocapital, là où l'expertise rencontre l'excellence, propulsant vos investissements vers de nouveaux sommets.",
    },
    SecOurServices: {
      Title: "Nos Services Principaux",
      Text: "Gestion proactive assurant des portefeuilles équilibrés alignés sur vos objectifs d'investissement.",
    },
    SectionHeading: {
      Title: "Plateforme de Trading Multi-Marchés",
      Text: "Découvrez une plateforme de trading complète offrant un accès aux marchés mondiaux du forex, des actions, des matières premières, des contrats à terme, des crypto-monnaies et des CFD. Avec une analyse et des insights d'experts, saisissez les opportunités sur des marchés diversifiés, vous permettant de naviguer dans les fluctuations et d'atteindre vos objectifs financiers en toute confiance.",
    },

    SecWelcomeContent: {
      Discover: "Découvrez de nouvelles façons de profiter de votre monde !",
      Experience: "L'expérience mène au succès",
      For20:
        "Depuis plus de 20 ans, nous nous engageons pour l'excellence sur le marché boursier. Notre historique parle de lui-même, car nous avons constamment obtenu des résultats supérieurs et gagné la confiance de nos clients. Partenaire avec nous pour un voyage vers le succès financier.",
    },
  },

  Deutsch: {
    All: {
      Home: "Startseite",
      About: "Über uns",
      Markets: "Märkte",
      Pricing: "Preise",
      Register: "Registrierung",
      Login: "Anmelden",
      ReadMore: "Mehr lesen",
      JoinNow: "Jetzt beitreten",
      Navigate: "NAVIGIEREN",
      AboutUs: "Über uns",
      SignIn: "Anmelden",
      Account: "Konto",
      SignUp: "Registrieren",
      Email: "E-Mail",
      Password: "Passwort",
      LastName: "Nachname",
      FirstName: "Vorname",
      Address: "Adresse",
      PostalCode: "Postleitzahl",
      Phone: "Telefon",
      PromoCode: "Aktionscode",
      Country: "Land",
      Currency: "Währung",
      AllreadyHave: "Sie haben bereits ein Konto?",
      DontHaveAcc: "Sie haben noch kein Konto?",
      Loading: "Wird geladen",
      OurPricingPlans: "Unsere Preispläne"

    },
    SecWhoWeContant: {
      Charting:
        "Planen Sie Ihre finanzielle Zukunft: Wo Fachwissen auf Chancen trifft",
      AtCoin:
        "Bei Coineurocapital können wir auf eine über zwei Jahrzehnte lange Geschichte an der Börse zurückblicken, die uns zu vertrauenswürdigen Partnern auf Ihrem Weg zum finanziellen Wohlstand macht. Unser Expertenteam bietet maßgeschneiderte Handelslösungen, die sorgfältig auf Ihre individuellen Anlageziele und Risikobereitschaft zugeschnitten sind. Von aufschlussreichen Marktanalysen bis hin zum praktischen Portfoliomanagement ermöglichen wir Ihnen, fundierte Entscheidungen zu treffen und lukrative Möglichkeiten zu nutzen. Erleben Sie den Unterschied mit Coineurocapital, wo Fachwissen auf Exzellenz trifft und Ihre Investitionen auf ein neues Niveau treibt.",
    },
    SecOurServices: {
      Title: "Unsere Kernleistungen",
      Text: "Proaktives Management sorgt für ausgewogene Portfolios, die Ihren Anlagezielen entsprechen.",
    },
    SectionHeading: {
      Title: "Multi-Market-Trading-Plattform",
      Text: "Entdecken Sie eine umfassende Handelsplattform, die Zugang zu globalen Devisen, Aktien, Rohstoffen, Futures, Kryptowährungen und CFDs bietet. Mit Expertenanalysen und Erkenntnissen bieten wir Ihnen sechzehn Möglichkeiten in verschiedenen Märkten, die es Ihnen ermöglichen, Schwankungen zu meistern und Ihre finanziellen Ziele mit Zuversicht zu erreichen.",
    },
    SecWelcomeContent: {
      Discover: "Entdecken Sie neue Möglichkeiten, Ihre Welt zu genießen!",
      Experience: "Erfahrung treibt den Erfolg an",
      For20:
        "Seit über 20 Jahren engagieren wir uns für Spitzenleistungen an der Börse. Unsere Erfolgsbilanz spricht für sich, denn wir haben stets hervorragende Ergebnisse geliefert und das Vertrauen unserer Kunden gewonnen. Begleiten Sie uns auf dem Weg zum finanziellen Erfolg.",
    },
    Markets: {
      Forex: "Forex",
      Stocks: "Aktien",
      Commodities: "Rohstoffe",
      Futures: "Futures",
      Crypto: "Krypto",
      CFDs: "CFDs",
    },
    Pricing: {
      Title: "Unsere Preispläne",
      StarterPlan: {
        Price: "250 USD",
        Features: [
          "Zugriff auf grundlegende Marktanalyseberichte",
          "Begrenzte Sprechstunden pro Monat",
          "Bildungsressourcen und einsteigerfreundliche Tools",
          "E-Mail-Unterstützung",
        ],
      },
      TraderPlan: {
        PriceRange: "500 bis 1000 USD",
        Features: [
          "Umfassende Marktanalyse und Handelsempfehlungen",
          "Verlängerte Sprechstunden für eine individuelle Beratung",
          "Erweiterte Handelstools und Indikatoren",
          "Bevorzugter E-Mail- und Chat-Support",
          "Exklusive Webinare und Lehrmaterialien",
        ],
      },
      AdvancedPlan: {
        Price: "5.000 USD und mehr",
        Features: [
          "Erstklassige Markteinblicke mit detaillierten Forschungsberichten",
          "Unbegrenzte Beratung durch einen engagierten Account Manager",
          "Zugriff auf exklusive Handelsalgorithmen und -strategien",
          "Vorrangiger Zugang zum Kundensupport per Telefon, E-Mail und Chat",
          "VIP-Einladungen zu Branchenveranstaltungen und Seminaren",
        ],
      },
    },
    AboutUs: {
      Title: "Über uns",
      Content:
        "Mit mehr als zwei Jahrzehnten unerschütterlichem Engagement für Exzellenz gilt Coineuro Capital als Vorbild für Zuverlässigkeit und Fachwissen in der Welt der Finanzmärkte. Seit unserer Gründung haben wir uns kontinuierlich weiterentwickelt und unsere 20-jährige Erfahrung genutzt, um die Komplexität der Handelslandschaft mit Präzision und Weitsicht zu meistern. Unsere Reise begann mit einer einfachen, aber ehrgeizigen Vision: Händler aller Ebenen mit dem Wissen und den Werkzeugen auszustatten, die sie zum Erreichen ihrer finanziellen Ziele benötigen.",
    },
  },
};
