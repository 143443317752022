import Footer from "../layouts/FooterPages";
import Header from "../layouts/Header";
import SecOurFeatures from "../template/HomeDemo1/SecOurFeatures";
import { ServiceBlock } from "../data/data-containers/data-HomeDemo1";
import { useContext } from "react";
import { LanguageContext } from "../languagesContext";
const Pricing = () => {
  const {language} = useContext(LanguageContext)
  return (
    <div style={{ marginTop: "100px" }}>
      <Header />
      <SecOurFeatures data={ServiceBlock[language]} />
      <Footer />
    </div>
  );
};
export default Pricing;
