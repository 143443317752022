import { useContext } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { LanguageContext } from "../../../../languagesContext"
import { languages } from "../../../../languages"

function SecWelcomeContent({img}){
  const {language} = useContext(LanguageContext)
  const selectedLanguage = languages[language]
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img src={img} width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">{selectedLanguage.SecWelcomeContent.Discover}</span>
              </div>
          </div>
          <h1>{selectedLanguage.SecWelcomeContent.Experience}</h1>
          <p>
          {selectedLanguage.SecWelcomeContent.For20}
          </p>
          <div className="dream-btn-group">
            <Link to='signup'>
              <a href="#" className="btn dream-btn">{selectedLanguage.All.JoinNow}</a>
            </Link>
          </div>
      </div>
  )
}

export default SecWelcomeContent